<template>
  <!-- 新增企业 -->
  <el-dialog title="新增医院" :visible.sync="dialogFormVisible" :append-to-body="true" @close="hideAddHospitalDialog">
    <el-form
      :model="hospitalForm"
      :rules="hospitalRules"
      label-position='right'
      ref="hospitalForm"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="医院名称：" prop="name" label-width="100px">
            <hospital-selector
              v-model="hospitalForm.originId"
              :name.sync="hospitalForm.name"
              placeholder="请输入医院名称"
              @change="handleHospitalSelect">
            </hospital-selector>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="统一社会信用代码：" prop="socialCode" label-width="145px">
            <el-input
              v-model="hospitalForm.socialCode"
              disabled
              placeholder="请输入统一社会信用代码"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="医院状态：" prop="status" label-width="100px">
            <el-select v-model="hospitalForm.status" placeholder="请选择医院状态">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所在地区：" prop="areaId" label-width="145px">
            <area-cascader
              :provinceId.sync="hospitalForm.provinceId"
              :provinceName.sync="hospitalForm.provinceName"
              :cityId.sync="hospitalForm.cityId"
              :cityName.sync="hospitalForm.cityName"
              :areaId.sync="hospitalForm.areaId"
              :areaName.sync="hospitalForm.areaName"
              @handleValidate='handleValidate'
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="详细地址：" prop="address" label-width="100px">
            <el-input
              v-model="hospitalForm.address"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="医院座机：" prop="tel" label-width="100px">
            <el-input
              v-model="hospitalForm.tel"
              :maxlength="20"
              placeholder="请输入医院座机"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="医院传真：" prop="fax" label-width="145px">
            <el-input
              v-model="hospitalForm.fax"
              :maxlength="20"
              placeholder="请输入医院传真"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="医院邮箱：" prop="email" label-width="100px">
            <el-input
              v-model="hospitalForm.email"
              :maxlength="50"
              placeholder="请输入医院邮箱"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注：" prop="remark" label-width="100px">
            <el-input
              v-model="hospitalForm.remark"
              type="textarea"
              :rows="3"
              :maxlength="200"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialog-footer" style="text-align: right;">
      <el-button plain @click="hideAddHospitalDialog">取消</el-button>
      <el-button type="primary" @click="saveHospital">保存</el-button>
    </div>
    </el-dialog>
</template>
<script>
import CustomerApi from '@/api/customer';
import HospitalSelector from '@/components/hospitalSelector';
import AreaCascader from '@/components/common/areaCascader';

export default {
  name: 'HOSPITAL_DIALOG',
  components: {
    HospitalSelector,
    AreaCascader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogFormVisible: {
      get () {
        return this.show;
      },
      set () {},
    },
  },
  data () {
    const checkEmail = (rule, value, cb) => {
      const regEmail = /^(?=.*@)(?=.*\.)[^@.].*[^@.]$/;
      if (['', null].includes(value) || regEmail.test(value)) {
        return cb();
      }
      cb(new Error('请输入合法的邮箱'));
    };
    return {
      hospitalForm: {
        address: '',
        areaId: '',
        areaName: '',
        cityId: '',
        cityName: '',
        email: '',
        fax: '',
        name: '',
        provinceId: '',
        provinceName: '',
        remark: '',
        status: 'EFFECTIVE',
        tel: '',
        originId: '',
        socialCode: '',
        coordinationStatus: '',
      },
      hospitalRules: {
        name: [{ required: true, message: '请输入医院名称', trigger: 'blur' }],
        areaId: [{ required: true, message: '请选择地址', trigger: 'blur' }],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        status: [
          { required: true, message: '请选择医院状态', trigger: 'change' },
        ],
        email: [
          {validator: checkEmail, trigger: 'blur'},
        ],
      },
      statusOptions: [
        {
          value: 'EFFECTIVE',
          label: '有效',
        },
        {
          value: 'INVALID',
          label: '无效',
        },
        {
          value: 'DRAFT',
          label: '草稿',
        },
      ],
    };
  },
  methods: {
    // TODO 请求判断结果
    handleValidate () {
      this.$refs['hospitalForm'].clearValidate('areaId');
    },
    handleHospitalSelect (item) {
      this.hospitalForm.socialCode = item.enterpriseCertCode;
      // TODO 移除判断条件
      this.$refs['hospitalForm'].clearValidate('name');
    },
    hideAddHospitalDialog () {
      this.$refs['hospitalForm'].resetFields();
      this.$emit('close');
    },
    saveHospital () {
      this.$refs['hospitalForm'].validate((valid) => {
        if (valid) {
          CustomerApi.addCustomer(this.hospitalForm).then(() => {
            this.$message({
              message: '新增成功',
              type: 'success',
            });
            this.$local.set('changeCustomer', 'yes');
            this.hideAddHospitalDialog();
          });
        }
      });
    },
  },
};
</script>
